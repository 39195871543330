.Call-to-action {
  padding-top: 20px; display: inline-block; font-size: 14px;
  a { 
    padding: 8px 15px; color:$white; border:1px solid darken($grey-dark, 5); @include gradient(darken($grey-dark, 10), $grey-dark); text-transform: uppercase; border-radius: 5px; 
    &:hover { background: darken($grey-dark, 15); }
  }

	&.Call-to-action--small { font-size: 12px; }
	&.Call-to-action--big { font-size: 30px; }
	&.Call-to-action--no-top-padding { padding-top: 0px; }

	&.Call-to-action--center { text-align: center; }
	&.Call-to-action--left { text-align: left; }
	&.Call-to-action--right { text-align: right; }

}