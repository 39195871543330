@media screen and (max-width: $mobile) {

	#Main {
		display: block; }
	#SideMenu, #PhotoBig, #MainContent {
		width: 100%; }

	#PhotoBig .PhotoBig__box.PhotoBig--internal, #PhotoBig .PhotoBig__box.PhotoBig--product {
		position: relative; width: 100%;
	}

}