@mixin gradient($from, $to) {
	background: $from;
	background: -moz-linear-gradient(top, $from 0%, $to 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
	background: -webkit-linear-gradient(top, $from 0%, $to 100%); 
	background: -o-linear-gradient(top,  $from 0%, $to 100%);
	background: -ms-linear-gradient(top,  $from 0%,$to 100%);
	background: linear-gradient(to bottom,  $from 0%,$to 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 );
}

@mixin radial($from, $to) {
	background: $from; /* Old browsers */
	background: -moz-radial-gradient(center, ellipse cover, $from 0%, $to 100%); /* FF3.6+ */
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$from), color-stop(100%,$to)); /* Chrome,Safari4+ */
	background: -webkit-radial-gradient(center, ellipse cover, $from 0%,$to 100%); /* Chrome10+,Safari5.1+ */
	background: -o-radial-gradient(center, ellipse cover, $from 0%,$to 100%); /* Opera 12+ */
	background: -ms-radial-gradient(center, ellipse cover, $from 0%,$to 100%); /* IE10+ */
	background: radial-gradient(ellipse at center, $from 0%,$to 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}