// MEDIA QUERY

$mobile: 480px;
$pad: 650px;
$desktop: 1000px;

// SITW WIDTH

$content-width: 960px;

// Fonts:

$font: 'Average Sans', sans-serif;
$fontTitle: 'Nothing You Could Do', cursive;
$fontBold: 'Average Sans', sans-serif;

// Palette

$blue: #025bb9;
$blue-light: #2187f3;
$white: #ffffff;
$black: #000000;
$grey: #666;
$grey-dark: #4c4b59;
$grey-light: #b0b2b3;
$red: #6d0f0f;
$gold: #877859;
$purple: #391753;