@mixin coloumns($number, $gutter) {
	width: (100 / 12) * $number - ($gutter * 2) * 0%;
	margin: $gutter * 0%;
	float: left;
}


.row { @include row(); margin: 0px 0px; }
.content { @include outer-container($content-width); }

.col-twelve { @include coloumns(12, 0); }
.col-eleven { @include coloumns(11, 0); }
.col-ten { @include coloumns(10, 0); }
.col-nine { @include coloumns(9, 0 ); }
.col-eight { @include coloumns(8, 0 ); }
.col-seven { @include coloumns(7, 0 ); }
.col-six { @include coloumns(6, 0 ); }
.col-five { @include coloumns(5, 0 ); }
.col-four { @include coloumns(4, 0 ); }
.col-tree { @include coloumns(3, 0 ); }
.col-two { @include coloumns(2, 0 ); }
.col-one { @include coloumns(1, 0 ); }
.col-none { display:none; }

.col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-tree, .col-two, .col-one { min-height: 1px; }

@media screen and (max-width: $pad) {
	.col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-tree, .col-two, .col-one { @include coloumns(12, 0);  }

	.col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-tree, .col-two, .col-one { padding-left:10px;  padding-right:10px;  }

	.col-pad-twelve { @include coloumns(12, 0); }
	.col-pad-eleven { @include coloumns(11, 0); }
	.col-pad-ten { @include coloumns(10, 0); }
	.col-pad-nine { @include coloumns(9, 0 ); }
	.col-pad-eight { @include coloumns(8, 0 ); }
	.col-pad-seven { @include coloumns(7, 0 ); }
	.col-pad-six { @include coloumns(6, 0 ); }
	.col-pad-five { @include coloumns(5, 0 ); }
	.col-pad-four { @include coloumns(4, 0 ); }
	.col-pad-tree { @include coloumns(3, 0 ); }
	.col-pad-two { @include coloumns(2, 0 ); }
	.col-pad-one { @include coloumns(1, 0 ); }
	.col-pad-none { display:none; }
}

@media screen and (max-width: $mobile) {

	.col-pad-twelve, .col-pad-eleven, .col-pad-ten, .col-pad-nine, .col-pad-eight, .col-pad-seven, .col-pad-six, .col-pad-five, .col-pad-four, .col-pad-tree, .col-pad-two, .col-pad-one { @include coloumns(12, 0);  }

	.col-mobile-twelve { @include coloumns(12, 0); }
	.col-mobile-eleven { @include coloumns(11, 0); }
	.col-mobile-ten { @include coloumns(10, 0); }
	.col-mobile-nine { @include coloumns(9, 0 ); }
	.col-mobile-eight { @include coloumns(8, 0 ); }
	.col-mobile-seven { @include coloumns(7, 0 ); }
	.col-mobile-six { @include coloumns(6, 0 ); }
	.col-mobile-five { @include coloumns(5, 0 ); }
	.col-mobile-four { @include coloumns(4, 0 ); }
	.col-mobile-tree { @include coloumns(3, 0 ); }
	.col-mobile-two { @include coloumns(2, 0 ); }
	.col-mobile-one { @include coloumns(1, 0 ); }
	.col-mobile-none { display:none; }
}