#Menu {
	position: relative; padding-top: 0px;
	ul { padding: 10px; }
	li { 
		display: block; font-family: $font;

		a { display:block; color:$grey; font-size: 16px; font-weight: normal; background: none; text-transform: uppercase; }

		&.active a,
		a:hover {
			color: $black; background: $grey-light; 
		}
		&.active ul li a { color: $grey; background: none; } 
		&:hover ul { display:block; }
		ul {
			padding: 10px 0px;  
			li {  
				display:block; text-align: left; font-family: $fontTitle; 
				a { 
					display:block; padding: 4px 0px; margin: 0px; color: $grey; text-transform: none; background: none; font-size: 19px;
				}
				&.active a, a:hover {  color:$black; background:$grey-light;  }
			}
		}
	}

}



.slicknav_menu { 
	display:none; 
	z-index: 10;
    position: absolute;
    width: 100%;
}

@media screen and (max-width: $mobile) {
	header {
		.topMenu ul#Menu { display:none; }
		.topMenu { border-bottom: 0px; }
	}
	.slicknav_menu { display:block; }
	header { padding-top: 50px; }
}
