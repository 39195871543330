.cards {
  @include display(flex);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
}

.card {
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $card-border-color: $base-border-color;
  $card-border: 1px solid $card-border-color;
  $card-background: lighten($card-border-color, 10%);
  $card-header-background: $card-background;
  $card-margin: 1em;
  $card-image-hover-opacity: 0.7;
  $card-image-hover-color: #F8F2B4;

  @include flex-basis(15em);
  @include flex-grow(1);
  @include transition (all 0.2s ease-in-out);
  background-color: $card-background;
  border-radius: $base-border-radius;
  border: $card-border;
  box-shadow: 0 2px 4px darken($base-background-color, 10%);
  cursor: pointer;
  margin: 0 $card-margin $base-spacing $card-margin;
  position: relative;


  .card-image {
    overflow: hidden;
    height: 150px;
    max-height: 150px;
    background-color: $card-image-hover-color;

    img {
      @include transition (all 0.2s ease-in-out);
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      width: 100%;
      opacity: 1;
    }
  }

  .card-header {
    @include transition (all 0.2s ease-in-out);
    background-color: $card-header-background;
    border-bottom: $card-border;
    border-radius: $base-border-radius $base-border-radius 0 0;
    font-weight: bold;
    line-height: 1.5em;
    padding: ($base-spacing / 3) ($base-spacing / 2);
  }

  .card-copy {
    font-size: 0.9em;
    line-height: 1.5em;
    padding: ($base-spacing / 2) ($base-spacing / 2);

    p {
      margin: 0 0 ($base-spacing / 2);
    }
  }

  &:focus,
  &:hover {
    cursor: pointer;

    img {
      opacity: $card-image-hover-opacity;
    }
  }

  &:active {
    background-color: $card-background;

    .card-header {
      background-color: $card-background;
    }
  }
}
