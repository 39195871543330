@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  appearance: none;
  background-color: #477dca;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus {
    background-color: #3964a2;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover {
      background-color: #477dca; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple=multiple] {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple=multiple]:hover {
    border-color: #b1b1b1; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple=multiple]:focus {
    border-color: #477dca;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(55, 112, 192, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple=multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple=multiple]:disabled:hover {
      border: 1px solid #ddd; }

textarea {
  resize: vertical; }

input[type="search"] {
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: inline-block; }

input[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  color: #333;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #477dca;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #355e98; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

@font-face {
  font-family: 'encode_sansbold';
  src: url("../../fonts/encodesans-bold-webfont.woff2") format("woff2"), url("../../fonts/encodesans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'encode_sansregular';
  src: url("../../fonts/encodesans-regular-webfont.woff2") format("woff2"), url("../../fonts/encodesans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'encode_sansthin';
  src: url("../../fonts/encodesans-thin-webfont.woff2") format("woff2"), url("../../fonts/encodesans-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../../fonts/icomoon.eot?gtwgo7");
  src: url("../../fonts/icomoon.eot?gtwgo7#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?gtwgo7") format("truetype"), url("../../fonts/icomoon.woff?gtwgo7") format("woff"), url("../../fonts/icomoon.svg?gtwgo7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-bagfull:before {
  content: "\e901"; }

.icon-user:before {
  content: "\e900"; }

.icon-bag:before {
  content: "\e902"; }

.row {
  display: block;
  margin: 0px 0px; }
  .row::after {
    clear: both;
    content: "";
    display: table; }

.content {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto; }
  .content::after {
    clear: both;
    content: "";
    display: table; }

.col-twelve {
  width: 100%;
  margin: 0%;
  float: left; }

.col-eleven {
  width: 91.6666666667%;
  margin: 0%;
  float: left; }

.col-ten {
  width: 83.3333333333%;
  margin: 0%;
  float: left; }

.col-nine {
  width: 75%;
  margin: 0%;
  float: left; }

.col-eight {
  width: 66.6666666667%;
  margin: 0%;
  float: left; }

.col-seven {
  width: 58.3333333333%;
  margin: 0%;
  float: left; }

.col-six {
  width: 50%;
  margin: 0%;
  float: left; }

.col-five {
  width: 41.6666666667%;
  margin: 0%;
  float: left; }

.col-four {
  width: 33.3333333333%;
  margin: 0%;
  float: left; }

.col-tree {
  width: 25%;
  margin: 0%;
  float: left; }

.col-two {
  width: 16.6666666667%;
  margin: 0%;
  float: left; }

.col-one {
  width: 8.3333333333%;
  margin: 0%;
  float: left; }

.col-none {
  display: none; }

.col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-tree, .col-two, .col-one {
  min-height: 1px; }

@media screen and (max-width: 650px) {
  .col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-tree, .col-two, .col-one {
    width: 100%;
    margin: 0%;
    float: left; }
  .col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-tree, .col-two, .col-one {
    padding-left: 10px;
    padding-right: 10px; }
  .col-pad-twelve {
    width: 100%;
    margin: 0%;
    float: left; }
  .col-pad-eleven {
    width: 91.6666666667%;
    margin: 0%;
    float: left; }
  .col-pad-ten {
    width: 83.3333333333%;
    margin: 0%;
    float: left; }
  .col-pad-nine {
    width: 75%;
    margin: 0%;
    float: left; }
  .col-pad-eight {
    width: 66.6666666667%;
    margin: 0%;
    float: left; }
  .col-pad-seven {
    width: 58.3333333333%;
    margin: 0%;
    float: left; }
  .col-pad-six {
    width: 50%;
    margin: 0%;
    float: left; }
  .col-pad-five {
    width: 41.6666666667%;
    margin: 0%;
    float: left; }
  .col-pad-four {
    width: 33.3333333333%;
    margin: 0%;
    float: left; }
  .col-pad-tree {
    width: 25%;
    margin: 0%;
    float: left; }
  .col-pad-two {
    width: 16.6666666667%;
    margin: 0%;
    float: left; }
  .col-pad-one {
    width: 8.3333333333%;
    margin: 0%;
    float: left; }
  .col-pad-none {
    display: none; } }

@media screen and (max-width: 480px) {
  .col-pad-twelve, .col-pad-eleven, .col-pad-ten, .col-pad-nine, .col-pad-eight, .col-pad-seven, .col-pad-six, .col-pad-five, .col-pad-four, .col-pad-tree, .col-pad-two, .col-pad-one {
    width: 100%;
    margin: 0%;
    float: left; }
  .col-mobile-twelve {
    width: 100%;
    margin: 0%;
    float: left; }
  .col-mobile-eleven {
    width: 91.6666666667%;
    margin: 0%;
    float: left; }
  .col-mobile-ten {
    width: 83.3333333333%;
    margin: 0%;
    float: left; }
  .col-mobile-nine {
    width: 75%;
    margin: 0%;
    float: left; }
  .col-mobile-eight {
    width: 66.6666666667%;
    margin: 0%;
    float: left; }
  .col-mobile-seven {
    width: 58.3333333333%;
    margin: 0%;
    float: left; }
  .col-mobile-six {
    width: 50%;
    margin: 0%;
    float: left; }
  .col-mobile-five {
    width: 41.6666666667%;
    margin: 0%;
    float: left; }
  .col-mobile-four {
    width: 33.3333333333%;
    margin: 0%;
    float: left; }
  .col-mobile-tree {
    width: 25%;
    margin: 0%;
    float: left; }
  .col-mobile-two {
    width: 16.6666666667%;
    margin: 0%;
    float: left; }
  .col-mobile-one {
    width: 8.3333333333%;
    margin: 0%;
    float: left; }
  .col-mobile-none {
    display: none; } }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.align-center {
  text-align: center; }

.align-justify {
  text-align: justify; }

h1, h2, h3, h4, h5 {
  font-family: "Nothing You Could Do", cursive;
  font-weight: normal; }

p, ul, li, label {
  font-family: "Average Sans", sans-serif;
  color: #000000; }

h1 {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  font-family: "Nothing You Could Do", cursive;
  color: #666; }

h2 {
  font-size: 26px;
  line-height: 30px; }

h3 {
  font-size: 20px;
  line-height: 25px; }

h4 {
  font-size: 16px;
  line-height: 20px; }

ol, ul {
  padding: 0.5em 0px; }

ul.default li, ol.default li, ol li, ul li {
  list-style-position: inside; }

ul.default, ul {
  list-style-type: circle; }

ol.default, ol {
  list-style-type: upper-roman; }

p, li, label {
  font-size: 14px;
  line-height: 20px; }

ul {
  padding-left: 0px; }
  ul li {
    list-style: disc; }
    ul li ul {
      padding-left: 20px; }

p b, p strong, li b, li strong, b, strong {
  font-family: "Average Sans", sans-serif; }

.upper {
  text-transform: uppercase; }

.lower {
  text-transform: lowercase; }

.strip {
  text-decoration: line-through; }

a {
  color: #666;
  font-weight: 400;
  text-deoration: none; }
  a:hover {
    color: #877859; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"], select {
  font-family: "Average Sans", sans-serif; }

select {
  -webkit-appearance: none;
  width: 100%; }

input.btn {
  background: #ffffff;
  color: #000000;
  cursor: pointer;
  border-radius: 0px;
  width: auto;
  padding: 5px;
  letter-spacing: 0.2em;
  border: 1px solid #b0b2b3; }
  input.btn:hover {
    background: #ffffff;
    border: 1px solid #b0b2b3;
    color: #000000; }

p.errore {
  margin: 2px 0px;
  padding: 3px 5px;
  color: #ffffff;
  background: #6d0f0f; }

label {
  font-size: 17px;
  color: #b0b2b3; }

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea,
select[multiple=multiple],
select {
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  font-family: "Average Sans", sans-serif;
  font-size: 15px;
  padding: 5px;
  transition: border-color 150ms ease;
  width: 100%;
  color: #666;
  margin: 2px; }

input[type="checkbox"] {
  vertical-align: middle;
  float: left;
  display: block;
  padding: 4px;
  background: #ffffff;
  border: 1px solid #b0b2b3;
  margin-right: 5px; }

#Menu {
  position: relative;
  padding-top: 0px; }
  #Menu ul {
    padding: 10px; }
  #Menu li {
    display: block;
    font-family: "Average Sans", sans-serif; }
    #Menu li a {
      display: block;
      color: #666;
      font-size: 16px;
      font-weight: normal;
      background: none;
      text-transform: uppercase; }
    #Menu li.active a,
    #Menu li a:hover {
      color: #000000;
      background: #b0b2b3; }
    #Menu li.active ul li a {
      color: #666;
      background: none; }
    #Menu li:hover ul {
      display: block; }
    #Menu li ul {
      padding: 10px 0px; }
      #Menu li ul li {
        display: block;
        text-align: left;
        font-family: "Nothing You Could Do", cursive; }
        #Menu li ul li a {
          display: block;
          padding: 4px 0px;
          margin: 0px;
          color: #666;
          text-transform: none;
          background: none;
          font-size: 19px; }
        #Menu li ul li.active a, #Menu li ul li a:hover {
          color: #000000;
          background: #b0b2b3; }

.slicknav_menu {
  display: none;
  z-index: 10;
  position: absolute;
  width: 100%; }

@media screen and (max-width: 480px) {
  header .topMenu ul#Menu {
    display: none; }
  header .topMenu {
    border-bottom: 0px; }
  .slicknav_menu {
    display: block; }
  header {
    padding-top: 50px; } }

.pagination {
  padding: 10px 0px;
  margin: 20px 0px 10px 0px;
  position: relative;
  overflow: hidden;
  text-align: center; }
  .pagination li {
    display: inline-block;
    list-style: none;
    padding: 3px;
    margin: 0px 3px; }
    .pagination li a {
      color: #ffffff; }
    .pagination li.active a, .pagination li a:hover, .pagination li.disabled, .pagination li.active {
      color: #b0b2b3; }

.Language .Language__item {
  color: #b0b2b3;
  font-weight: normal; }
  .Language .Language__item.Language--active, .Language .Language__item:hover {
    color: #ffffff; }

table {
  width: 100%; }
  table th, table td {
    font-size: 15px;
    color: #666; }
  table th {
    border-bottom: 0px; }

.cards {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }

.card {
  -webkit-flex-basis: 15em;
  -moz-flex-basis: 15em;
  flex-basis: 15em;
  -ms-flex-preferred-size: 15em;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #f7f7f7;
  border-radius: 3px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px #e6e6e6;
  cursor: pointer;
  margin: 0 1em 1.5em 1em;
  position: relative; }
  .card .card-image {
    overflow: hidden;
    height: 150px;
    max-height: 150px;
    background-color: #F8F2B4; }
    .card .card-image img {
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      width: 100%;
      opacity: 1; }
  .card .card-header {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ddd;
    border-radius: 3px 3px 0 0;
    font-weight: bold;
    line-height: 1.5em;
    padding: 0.5em 0.75em; }
  .card .card-copy {
    font-size: 0.9em;
    line-height: 1.5em;
    padding: 0.75em 0.75em; }
    .card .card-copy p {
      margin: 0 0 0.75em; }
  .card:focus, .card:hover {
    cursor: pointer; }
    .card:focus img, .card:hover img {
      opacity: 0.7; }
  .card:active {
    background-color: #f7f7f7; }
    .card:active .card-header {
      background-color: #f7f7f7; }

.label-switch {
  border-radius: 32px;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  position: relative;
  width: 52px; }
  .label-switch input[type="checkbox"] {
    display: none; }
    .label-switch input[type="checkbox"] + .checkbox {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      background: #e8e8e8;
      border-radius: 32px;
      border: none;
      cursor: pointer;
      height: 32px;
      margin: 0;
      padding: 0;
      position: relative;
      width: 52px;
      z-index: 0; }
      .label-switch input[type="checkbox"] + .checkbox:before {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 2px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        background: #fff;
        border-radius: 32px;
        content: "";
        height: 28px;
        width: 48px;
        z-index: 1; }
      .label-switch input[type="checkbox"] + .checkbox:after {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 2px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        height: 28px;
        width: 28px;
        background: #fff;
        border-radius: 28px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        content: "";
        z-index: 2; }
    .label-switch input[type="checkbox"]:checked + .checkbox {
      background: #477dca; }
      .label-switch input[type="checkbox"]:checked + .checkbox:before {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0); }
      .label-switch input[type="checkbox"]:checked + .checkbox:after {
        left: 22px; }

.Call-to-action {
  padding-top: 20px;
  display: inline-block;
  font-size: 14px; }
  .Call-to-action a {
    padding: 8px 15px;
    color: #ffffff;
    border: 1px solid #403f4b;
    background: #34343d;
    background: -moz-linear-gradient(top, #34343d 0%, #4c4b59 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #34343d), color-stop(100%, #4c4b59));
    background: -webkit-linear-gradient(top, #34343d 0%, #4c4b59 100%);
    background: -o-linear-gradient(top, #34343d 0%, #4c4b59 100%);
    background: -ms-linear-gradient(top, #34343d 0%, #4c4b59 100%);
    background: linear-gradient(to bottom, #34343d 0%, #4c4b59 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 );
    text-transform: uppercase;
    border-radius: 5px; }
    .Call-to-action a:hover {
      background: #29282f; }
  .Call-to-action.Call-to-action--small {
    font-size: 12px; }
  .Call-to-action.Call-to-action--big {
    font-size: 30px; }
  .Call-to-action.Call-to-action--no-top-padding {
    padding-top: 0px; }
  .Call-to-action.Call-to-action--center {
    text-align: center; }
  .Call-to-action.Call-to-action--left {
    text-align: left; }
  .Call-to-action.Call-to-action--right {
    text-align: right; }

html, body {
  width: 100%;
  height: 100%;
  position: relative; }

body {
  background: #ffffff; }

#Main {
  display: flex;
  min-height: 100%; }

#SideMenu {
  min-height: 100%;
  width: 280px;
  background: url(../../img/col-sx.jpg) top center repeat-x #ffffff;
  box-shadow: 0px 0px 8px rgba(50, 50, 50, 0.7);
  position: relative;
  z-index: 5; }

#PhotoBig {
  width: calc(50% - 140px); }
  #PhotoBig .PhotoBig__box {
    min-height: 100%;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover; }
    #PhotoBig .PhotoBig__box.PhotoBig--product {
      /* text-align: center;
			background-size: container;
			width: calc(50% - 140px);
			position: fixed;
			background-position: center center; */
      text-align: center;
      background-size: cover;
      width: calc(50% - 140px);
      position: fixed;
      background-position: center center; }
    #PhotoBig .PhotoBig__box.PhotoBig--internal {
      text-align: center;
      background-size: cover;
      width: calc(50% - 140px);
      position: fixed;
      background-position: center center; }

#MainContent {
  min-height: 100%;
  width: calc(50% - 140px);
  background: #ffffff; }

#ProductContent {
  min-height: 100%;
  width: calc(100% - 140px);
  background: #ffffff; }

.contenuto {
  padding: 20px;
  overflow: hidden; }

.CategorieMenu {
  padding: 4px 0px; }
  .CategorieMenu h2 {
    padding: 2px 10px; }
  .CategorieMenu.CategorieMenu--linea {
    border-top: 1px solid #b0b2b3; }

.logo {
  padding: 50px 10px 30px;
  text-align: center;
  display: block; }
  .logo img {
    max-width: 100%; }

h1.titoloCategoria {
  text-transform: uppercase;
  text-align: center;
  font-family: "Average Sans", sans-serif;
  color: #b0b2b3;
  padding-bottom: 0px;
  margin-bottom: 5px; }

h2.titoloCategoria {
  text-align: center;
  font-family: "Average Sans", sans-serif;
  color: #666;
  font-family: "Nothing You Could Do", cursive;
  font-size: 30px;
  margin-bottom: 30px; }

.Prodotti {
  overflow: hidden; }

.Prodotto {
  position: relative;
  overflow: hidden; }
  .Prodotto img.novita {
    position: absolute;
    width: 100px;
    max-width: 100%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    left: 50%; }
  .Prodotto .Prodotto__testo, .Prodotto img {
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out; }
  .Prodotto .Prodotto__testo {
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: center;
    background: #ffffff;
    color: #ffffff;
    width: 100%;
    padding-top: 10px;
    height: 0px;
    overflow: hidden; }
    .Prodotto .Prodotto__testo p {
      font-size: 18px; }
      .Prodotto .Prodotto__testo p .scontato {
        font-size: 15px; }
  .Prodotto:hover .Prodotto__testo {
    height: 150px; }
  .Prodotto a {
    display: block; }

.sliderProdottoContent {
  text-align: center; }
  .sliderProdottoContent .productsGallerySml li {
    list-style: none;
    display: inline-block; }

.prodottiSimili {
  padding: 20px 0px;
  border-top: 1px solid #b0b2b3;
  margin-top: 20px; }

.bx-wrapper {
  box-shadow: 0px 0px 0px;
  border: 0px; }
  .bx-wrapper .bx-loading {
    display: none; }

.black {
  color: #000000; }

.metodi img {
  vertical-align: middle;
  height: 50px; }

.azienda {
  display: none; }

.addedToWishlist, .addedToBag {
  position: absolute;
  top: 150px;
  right: 0px;
  display: none; }

.socialTitle {
  font-size: 20px; }

.Personal .addedToWishlist p, .Personal .addedToBag p {
  padding: 5px;
  background: #000000;
  color: #ffffff; }

.areaAcquista {
  padding: 20px 0px; }
  .areaAcquista .wishlistButton {
    border: 2px solid #ffffff;
    color: #ffffff;
    background: #000000;
    margin-top: 10px; }
  .areaAcquista .addButton {
    border: 2px solid #ffffff;
    color: #000000;
    background: #b0b2b3; }
  .areaAcquista .wishlistButton, .areaAcquista .addButton {
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Average Sans", sans-serif;
    display: block;
    text-align: center;
    padding: 5px;
    margin: 5px 0px; }
    .areaAcquista .wishlistButton:hover, .areaAcquista .addButton:hover {
      background: #666;
      color: #000000; }

.prezzo {
  font-size: 30px;
  color: #666;
  font-family: "Average Sans", sans-serif;
  text-align: right; }
  .prezzo strike {
    font-size: 20px;
    color: #666; }

.Personal {
  padding: 20px;
  margin: 10px 0px;
  border-bottom: 1px solid #b0b2b3; }
  .Personal p {
    font-size: 16px;
    color: #666;
    text-align: center;
    margin-bottom: 0px;
    text-transform: uppercase; }
  .Personal a {
    padding: 0px 5px;
    color: #666; }
    .Personal a:hover {
       color: #391753; }

.acquistafinale a {
  display: block;
  float: left;
  font-size: 15px;
  font-weight: normal;
  font-family: "Average Sans", sans-serif;
  border: 2px solid #ffffff;
  background: #b0b2b3;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  padding: 5px;
  width: 100%; }
  .acquistafinale a.continua {
    background: #000000;
    color: #ffffff; }
  .acquistafinale a:hover {
    background: #000000;
    color: #ffffff; }

.nascosto {
  display: none; }

.SocialFacebook, p.copy {
  padding: 10px;
  text-align: center;
  font-weight: normal;
  font-size: 12px; }

.areaAcquista {
  padding: 10px 0px;
  margin-bottom: 20px;
  border-top: 1px solid #b0b2b3;
  border-bottom: 1px solid #b0b2b3; }

.TabellaTaglie tr:first-child td {
  border-top: 1px solid #b0b2b3; }

.TabellaTaglie tr td {
  text-align: center;
  border-right: 1px solid #b0b2b3;
  padding: 5px;
  font-size: 13px; }

.TabellaTaglie tr td:first-child {
  text-align: left;
  border-left: 1px solid #b0b2b3;
  width: 150px; }

.Thumb {
  overflow: hidden;
  padding-bottom: 20px; }
  .Thumb .Thumb__gallery li {
    display: inline-block; }
  .Thumb .Thumb__gallery a {
    display: block;
    height: 100px;
    width: 100px;
    margin: 5px; }
    .Thumb .Thumb__gallery a img {
      border-radius: 50%;
      border: 1px solid #b0b2b3;
      opacity: 0.7; }
    .Thumb .Thumb__gallery a.active img, .Thumb .Thumb__gallery a:hover img {
      opacity: 1; }

.Box .Box__Internal {
  display: block;
  height: 100%;
  background-size: cover;
  -moz-background-size: cover;
  -webikit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.MenuMisurazioni {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 200px;
  text-transform: uppercase; }
  .MenuMisurazioni li {
    text-align: right;
    list-style: none;
    display: block;
    margin-bottom: 10px; }
    .MenuMisurazioni li a {
      padding: 5px;
      background: #666;
      color: #ffffff;
      display: block;
      margin: 10x 0px; }
    .MenuMisurazioni li.active a, .MenuMisurazioni li a:hover {
      background: #ffffff;
      color: #000000; }

.MenuMisurazioniNew li {
  list-style: none;
  display: block;
  margin-bottom: 40px;
  font-size: 35px;
  font-family: "Nothing You Could Do", cursive; }
  .MenuMisurazioniNew li a {
    padding: 0px 10px;
    background: #666;
    color: #ffffff;
    margin: 10x 0px; }
  .MenuMisurazioniNew li.active a {
    background: #ffffff;
    color: #666; }

@media screen and (max-width: 1000px) {
  .Box {
    height: 100px; }
    .Box.Box--cover {
      height: 200px; } }

h1.grey, h1 .grey {
  color: #4c4b59; }

h1.gold, h1 .gold {
  color: #877859; }

h1.serif, h1 .serif {
  font-family: "Average Sans", sans-serif; }

h1 big {
  font-size: 40px; }

h1 small {
  font-size: 30px; }

.Product-Detail h1 {
  font-size: 40px;
  color: #666; }

.Social {
  text-align: right;
  overflow: hidden;
  padding: 10px 0px; }

.checkout .col-twelve, .checkout .col-eleven, .checkout .col-ten, .checkout .col-nine, .checkout .col-eight, .checkout .col-seven, .checkout .col-six, .checkout .col-five, .checkout .col-four, .checkout .col-three, .checkout .col-two, .checkout .col-one {
  padding: 5px; }

.cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: rgba(102, 102, 102, 0.9);
  padding: 20px;
  z-index: 1000; }

.cookie p {
  font-size: 13px;
  color: #fff;
  text-align: center; }

.cookie a {
  font-weight: bold;
  padding: 3px;
  background: #000;
  color: #fff; }

.cookie a:hover {
  background: #fff;
  color: #000; }

@media screen and (max-width: 480px) {
  #Main {
    display: block; }
  #SideMenu, #PhotoBig, #MainContent {
    width: 100%; }
  #PhotoBig .PhotoBig__box.PhotoBig--internal, #PhotoBig .PhotoBig__box.PhotoBig--product {
    position: relative;
    width: 100%; } }
