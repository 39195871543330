button,
input[type="button"],
input[type="reset"],
input[type="submit"], select {
	font-family: $font;
}

select { -webkit-appearance:none; width: 100%; }

input.btn { 
	background: $white; color:$black; cursor: pointer; border-radius: 0px; width: auto; padding: 5px; letter-spacing:0.2em; border: 1px solid $grey-light;
	&:hover { background: $white; border: 1px solid $grey-light; color:$black }
}

p.errore {
	margin: 2px 0px; padding: 3px 5px; color:$white; background:$red;
}

label {
    font-size: 17px; color: $grey-light; 
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea,
select[multiple=multiple],
select {
	background-color: $white;
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
    font-family: $font;
    font-size: 15px;
    padding: 5px;
    transition: border-color 150ms ease;
    width: 100%;
    color: $grey;
    margin: 2px;
}

input[type="checkbox"] {
    vertical-align: middle; float: left; display:block; padding: 4px; background:$white; border:1px solid $grey-light; margin-right: 5px;
}

