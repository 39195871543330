
@font-face {
    font-family: 'encode_sansbold';
    src: url('../../fonts/encodesans-bold-webfont.woff2') format('woff2'),
         url('../../fonts/encodesans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'encode_sansregular';
    src: url('../../fonts/encodesans-regular-webfont.woff2') format('woff2'),
         url('../../fonts/encodesans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'encode_sansthin';
    src: url('../../fonts/encodesans-thin-webfont.woff2') format('woff2'),
         url('../../fonts/encodesans-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
