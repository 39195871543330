.align-left { text-align: left; }
.align-right { text-align: right; }
.align-center { text-align: center; }
.align-justify { text-align: justify; }

h1, h2, h3, h4, h5 { font-family: $fontTitle; font-weight: normal; }
p, ul ,li, label  { font-family: $font; color: $black; }

h1 { font-size: 30px; line-height: 35px; font-weight: bold; font-family: $fontTitle; color: $grey;  }
h2 { font-size: 26px; line-height: 30px; }
h3 { font-size: 20px; line-height: 25px; }
h4 { font-size: 16px; line-height: 20px; }

ol, ul { padding: 0.5em 0px; }
ul.default, ol.default, ol, ul { li { list-style-position: inside; } }
ul.default, ul { list-style-type: circle; }
ol.default, ol { list-style-type: upper-roman; }

p, li, label { font-size: 14px; line-height: 20px; }

ul {
	padding-left: 0px;
	li { list-style: disc; 
		ul { padding-left: 20px; }
	}
}

p b, p strong, li b, li strong, b, strong { font-family: $fontBold;  }

.upper { text-transform: uppercase; }
.lower { text-transform: lowercase; }
.strip { text-decoration: line-through; }
