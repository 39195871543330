html, body {
	width: 100%;
	height: 100%;
	position: relative;
}

body { 
	background:$white; 
}


#Main {
	display: flex;
	min-height: 100%;
}

#SideMenu {
	min-height: 100%;
	width: 280px;
	background: url(../../img/col-sx.jpg) top center repeat-x $white;
	box-shadow: 0px 0px 8px rgba(50, 50, 50, .7);
	position: relative;
	z-index: 5;
}

#PhotoBig {
	width: calc(50% - 140px);
	.PhotoBig__box {
		min-height: 100%;
		display: block;
		background-repeat: no-repeat;
		background-size: cover;
		-moz-background-size: cover; 
		-webkit-background-size: cover;

		&.PhotoBig--product {
			/* text-align: center;
			background-size: container;
			width: calc(50% - 140px);
			position: fixed;
			background-position: center center; */
			text-align: center;
			background-size: cover;
			width: calc(50% - 140px);
			position: fixed;
			background-position: center center;
		}

		&.PhotoBig--internal {
			text-align: center;
			background-size: cover;
			width: calc(50% - 140px);
			position: fixed;
			background-position: center center;
		}

	}
}

#MainContent {
	min-height: 100%;
	width: calc(50% - 140px);
	background: $white;
}

#ProductContent {
	min-height: 100%;
	width: calc(100% - 140px);
	background: $white;
}

.contenuto {
	padding: 20px;
	overflow: hidden;
}

.CategorieMenu {
	padding: 4px 0px;
	h2 { padding: 2px 10px; }
	&.CategorieMenu--linea { border-top: 1px solid $grey-light; }
}

.logo {
	padding: 50px 10px 30px; text-align: center; display:block;
	img { max-width: 100%; }
}

h1.titoloCategoria {
	text-transform: uppercase; text-align: center; font-family: $font; color:$grey-light; padding-bottom: 0px; margin-bottom: 5px;
}
h2.titoloCategoria {
	text-align: center; font-family: $font; color:$grey; font-family: $fontTitle; font-size: 30px; margin-bottom: 30px;
}

.Prodotti { overflow: hidden; }
.Prodotto {
	position: relative;
	overflow: hidden;
	img.novita {
		position: absolute; width: 100px; max-width: 100%; top: 50%; margin-top: -50px; margin-left: -50px; left: 50%;
	}
	.Prodotto__testo, img { transition: all .4s ease-in-out; -webkit-transition: all .4s ease-in-out; -moz-transition: all .4s ease-in-out; }
	.Prodotto__testo {
		position: absolute; bottom: 0px; left: 0px; text-align: center; background: $white; color: $white; width: 100%; padding-top: 10px; height: 0px; overflow: hidden;
		p {
			font-size: 18px;
			.scontato { font-size: 15px; }
		}
	}
	&:hover {
		.Prodotto__testo { height: 150px; }
	}
	a { display: block; }
}

.sliderProdottoContent {
	text-align: center;
	.productsGallerySml li { list-style: none; display: inline-block; }
}

.prodottiSimili {
	padding: 20px 0px; border-top: 1px solid $grey-light; margin-top: 20px;
}

.bx-wrapper {
	box-shadow: 0px 0px 0px;
	border: 0px;
	.bx-loading { display:none; }
}

.black {
	color: $black;
}



.metodi {
	img { vertical-align: middle; height: 50px; }
}
.azienda {
	display:none;
}

.addedToWishlist, .addedToBag {
		position: absolute; top: 150px; right: 0px; display:none;
	}

.socialTitle {
	font-size: 20px;
}

.Personal {
	.addedToWishlist, .addedToBag {
		p { padding: 5px; background:$black; color: $white; }
	}
}



.areaAcquista { 
	padding: 20px 0px;

	.wishlistButton { border: 2px solid $white; color: $white; background:$black; margin-top: 10px; }
	.addButton { border: 2px solid $white; color: $black; background:$grey-light; }

	.wishlistButton, .addButton {
		display: block; text-transform: uppercase; font-size: 16px; font-family: $fontBold; display: block; text-align: center; padding: 5px; margin: 5px 0px;
		&:hover { background: $grey; color:$black; }
	}

}

.prezzo {
	font-size: 30px; color: $grey; font-family: $font; text-align: right;
	strike { font-size: 20px; color: $grey; }
}

.Personal {
	padding: 20px; margin: 10px 0px; border-bottom: 1px solid $grey-light;
	p { font-size: 16px; color:$grey; text-align: center; margin-bottom: 0px; text-transform: uppercase; }
	a { padding: 0px 5px; color: $grey; 
		&:hover { color: $purple; }
	}
}

.acquistafinale { 
	a { 
		display: block; float: left; font-size: 15px; font-weight: normal; font-family: $fontBold; border: 2px solid $white; background:$grey-light; color: $black; text-transform: uppercase; text-align: center; padding: 5px; width: 100%;
		&.continua { background: $black; color: $white; }
		&:hover { background: $black; color: $white; }
	}
}

.nascosto {
	display:none;
}

.SocialFacebook, p.copy {
	padding: 10px; text-align: center; font-weight: normal; font-size: 12px;
}


.areaAcquista {
	padding: 10px 0px;
	margin-bottom: 20px;
	border-top: 1px solid $grey-light;
	border-bottom: 1px solid $grey-light;
}

.TabellaTaglie {
	tr {
		&:first-child {  
			td { border-top: 1px solid $grey-light; }
		}
		td { text-align: center; border-right: 1px solid $grey-light; padding: 5px; font-size: 13px; }
		td:first-child { text-align:left; border-left: 1px solid $grey-light; width: 150px; }
	}
}

.Thumb {
	overflow:hidden; padding-bottom: 20px;
	.Thumb__gallery {
		li { display: inline-block; }
		a { display: block; height: 100px; width: 100px; margin: 5px; 
		 	img { border-radius: 50%; border: 1px solid $grey-light; opacity: 0.7; }
		 	&.active, &:hover {
		 		img { opacity:1; }
		 	}
		}
	}
}

.Box { 

	.Box__Internal { display: block; height: 100%; 
		background-size:cover; 
		-moz-background-size:cover; 
		-webikit-background-size:cover; 
		background-repeat: no-repeat; 
		background-position: center center; 
	}
}


.MenuMisurazioni {
	position: absolute;
	top: 20px;
	right: 0px;
	width: 200px;
	text-transform: uppercase;
	li { 
		text-align: right;
		list-style: none;
		display: block;
		margin-bottom: 10px;
		a {
			padding: 5px;
			background: $grey;
			color: $white;
			display: block;
			margin: 10x 0px;
		}
		&.active a, a:hover {
			background: $white;
			color: $black;
		} 
	}	
}

.MenuMisurazioniNew {
	li { 
		list-style: none;
		display: block;
		margin-bottom: 40px;
		font-size: 35px;
		font-family: $fontTitle;
		a {
			padding: 0px 10px;
			background: $grey;
			color: $white;
			margin: 10x 0px;
		}
		&.active a {
			background: $white;
			color: $grey;
		} 
	}	
}


@media screen and (max-width: 1000px) {
	.Box { height: 100px; &.Box--cover { height: 200px; } }
}

h1.grey, h1 .grey { color: $grey-dark; }
h1.gold, h1 .gold { color: $gold; }
h1.serif, h1 .serif { font-family: $font; }
h1 big { font-size: 40px; }
h1 small { font-size: 30px; }

.Product-Detail {
	h1 { font-size: 40px; color: $grey; }
}

.Social {
	text-align: right; overflow: hidden; padding: 10px 0px;
}

.checkout {
	.col-twelve, .col-eleven, .col-ten, .col-nine, .col-eight, .col-seven, .col-six, .col-five, .col-four, .col-three, .col-two, .col-one {
		padding: 5px;
	}
}




.cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: rgba(102, 102, 102, 0.9);
  padding: 20px;
  z-index: 1000; }
  .cookie p {
    font-size: 13px;
    color: #fff;
    text-align: center; }
  .cookie a {
    font-weight: bold;
    padding: 3px;
    background: #000;
    color: #fff; }
    .cookie a:hover {
      background: #fff;
      color: #000; }