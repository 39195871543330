.label-switch {  
  $action-color: #477DCA !default;
  $base-background-color: white !default;
  $switch-width: 52px;
  $switch-padding: 2px;
  $switch-height: 32px;
  $switch-radius: $switch-height;
  $knob-size: $switch-height - ($switch-padding * 2);
  $knob-radius: $switch-height - ($switch-padding * 2);
  $knob-width: $knob-size;
  $switch-background: $base-background-color;
  $switch-border-background: darken($base-background-color, 9%);
  $switch-shadow: 0 2px 5px transparentize(black, 0.6);

  border-radius: $switch-radius;
  cursor: pointer;
  display: inline-block;
  height: $switch-height;
  position: relative;
  width: $switch-width;

  input[type="checkbox"] {
    display: none;

    + .checkbox {
      @include transition(all 0.3s ease);
      background: $switch-border-background;
      border-radius: $switch-radius;
      border: none;
      cursor: pointer;
      height: $switch-height;
      margin: 0;
      padding: 0;
      position: relative;
      width: $switch-width;
      z-index: 0;

      &:before {
        @include position(absolute, 2px 0 0 2px);
        @include transform(scale(1));
        @include transition(all 0.3s ease);
        background: $switch-background;
        border-radius: $switch-radius;
        content: "";
        height: $knob-radius;
        width: $switch-width - ($switch-padding * 2);
        z-index: 1;
      }

      &:after {
        @include position(absolute, 2px 0 0 2px);
        @include transition(all 0.3s ease);
        @include size($knob-size);
        background: $switch-background;
        border-radius: $knob-radius;
        box-shadow: $switch-shadow;
        content: "";
        z-index: 2;
      }
    }

    &:checked {
      + .checkbox {
        background: $action-color;

        &:before {
          @include transform(scale(0));
        }

        &:after {
          left: $switch-width - $knob-width - ($switch-padding);
        }
      }
    }
  }
}
